import { Grid } from "@mui/material"
import React from "react"
import { Link } from "gatsby"
import positions from "../../shared/Data/positions"
import "./positionSection.scss"

const PositionCard = ({ position, description, id }) => {
  return (
    <Link to={`/career-details/${id}`} key={id} className="position-link">
      <div className="card-main-wrapper ">
        <div
          key={id}
          className="position-card-wrapper"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div className="pc-inner-wrapper">
            <h4> {position} </h4>
            <div className="pc-underline" />
            <p className="pb-2"> {description} </p>
          </div>
        </div>
      </div>
    </Link>
  )
}

function PositionSection() {
  return (
    <div className="position-wrapper">
      <div className="container">
        <h3 data-aos="fade-down" data-aos-duration="1000">
          Open Positions
        </h3>
        <Grid container spacing={2}>
          {positions.map(position => (
            <Grid item md={4}>
              <PositionCard {...position} />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  )
}

export default PositionSection
