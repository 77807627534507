import React from "react"
import BenefitsSection from "./BenifitsSection"
import Hero from "./Hero"
import PositionSection from "./PositionsSection"
import ContactUsForCandidate from "../shared/Footer/ContactUsForCandidate"

function CareerPage() {
  const handleApplyButton = () => {
    if (typeof window !== "undefined") {
      const targetDiv = document.getElementById("apply-for-job")
      targetDiv.scrollIntoView({ behavior: "smooth" })
    }
  }
  return (
    <div>
      <Hero
        header={`Work with us and make a difference`}
        text={`Empowering positive change through code-
         be a part of something bigger and contribute your skills to projects that matter!`}
        className="career"
        onClickHandler={handleApplyButton}
      />
      <PositionSection />
      <BenefitsSection />
      <ContactUsForCandidate />
    </div>
  )
}

export default CareerPage
